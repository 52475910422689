import * as React from 'react';
import axios from 'axios';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import * as Styles from '../styles/pages/_login.module.scss';
import * as CommonStyles from '../styles/global/_common.module.scss';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import {Link, useIntl} from "gatsby-plugin-react-intl"
import Artwork from "../components/Artwork";
import {useState} from "react";

const PostArtworkPage = ({ location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [submitError, setSubmitError] = React.useState();
  const [submitComplete, setSubmitComplete] = React.useState();

  const clickAdd = (e) => {
    console.log("######## click add", e)
  }

  const onSubmit = async ({ data }) => {
    console.log("######## submit")
  };

  const testArtworkData = {
    image: 'test'
  };

  return (
    <Layout location={location}>
      <Seo title={t('contact.seo.title')} description={t('contact.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('users.postArtwork')}
      />
      <BackGround>
        <section className={CommonStyles.wrapper}>
          <div className={CommonStyles.container}>
            <div id="users" className={Styles.users}>
              <h1>作品登録</h1>
              <section>
                <h2>画像の拡大表示</h2>
                <div className={Styles.mg_b3}>
                  <select className={Styles.mg_t1} name="artist[image_display]" id="artist_image_display">
                    <option selected="selected" value="permit_users">全てのLIMITSユーザに許可</option>
                    <option value="forbid_users">拡大表示を禁止する</option>
                  </select>
                  <small>会員登録したLIMITSユーザへ、作品画像の拡大表示を許可するか選択出来ます。</small>
                </div>
              </section>
              <section>
                <h2>作品アップロード</h2>
                <div className={Styles.mg_b2}>先頭の画像が SNSシェア時の表示画像になります。</div>
                <div className={Styles.text_center + ' ' + Styles.mg_b2}>
                  <a className={Styles.btn_common + ' ' + Styles.icon_plus} href="javascript:void(0)" onClick={clickAdd}>
                    作品画像を追加
                  </a>
                </div>
                <div className={Styles.flex_container + ' ' + Styles.artist_works}>

                  <Artwork artworkData={testArtworkData} />
                  <Artwork />
                  <Artwork />
                  <Artwork />
                  <Artwork />

                </div>
              </section>

              <div className={Styles.text_center}>
                <input
                  type="submit"
                  name="commit"
                  value="更新する"
                  data-disable-with="更新する"
                  className={Styles.btn_primary}
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export default PostArtworkPage;
