import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery  } from 'gatsby';
import * as Styles from '../styles/pages/_login.module.scss';
import {useIntl} from "gatsby-plugin-react-intl";
import axios from "axios";
import moment from 'moment'

const getMicroCMSData = async (hitolimitsId) => {
  const serviceId = process.env.MICROCMS_SERVICE_ID
  const apiKey = process.env.API_KEY
  return await axios.get(`https://${serviceId}.microcms.io/api/v1/hitolimits/${hitolimitsId}`, {
    headers: {
      'Content-Type': 'application/json',
      'X-MICROCMS-API-KEY': apiKey
    }
  }).then(response => {
    return response
  })
}

const onsubmit = async ({ hitolimitsId, intl }) => {
  const t = (id) => intl.formatMessage({id})

  const apiKey = process.env.LIMITS_API_KEY
  const apiUrl = process.env.LIMITS_API_URL

  const button = document.getElementById(`vote-button-${hitolimitsId}`)
  if (button) {
    button.innerText = t('hitolimits.voting')
    button.style.background = '#57a7f5'
    button.style.pointerEvents = 'none'
  }

  await axios
    .post(apiUrl + `/api/hitolimits/voting/${hitolimitsId}`, {
      headers: {
        'Content-Type': 'application/json',
        'LIMITS-BATTLESYSTEM-API-KEY': apiKey
      }
    })
    .then((response) => {
      // alert('voted ' + hitolimitsId)
      button.innerText = t('hitolimits.voted')
      button.style.background = '#68cc27'
      button.style.pointerEvents = 'none'
      getMicroCMSData(hitolimitsId).then(r => {
        const element = document.getElementById(`vote-${hitolimitsId}`)
        element.innerText = r.data.vote_point
      })
    })
    .catch((error) => {
      console.error(error)
      button.innerText = t('hitolimits.voteFailed')
      button.style.background = '#666'
      button.style.pointerEvents = 'none'
      setTimeout(() => {
        button.innerText = t('hitolimits.vote')
        button.style.background = '#9061db'
        button.style.pointerEvents = 'auto'
      }, 5000)
    });
}

export const Artwork = ({ artworkData }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})

  return (
    <div className={Styles.flex_item + ' ' + Styles.item_30 + ' ' + Styles.for_sp + ' ' + Styles.graybox}>
      {/*画像未設定*/}
      {!artworkData?.imageUrl && (
        <div className={Styles.for_sp}>
          <label className={Styles.icon_required} htmlFor="artist_artist_works_images_attributes_1658428616694_image">
            画像ファイル
          </label>
          <input
            type="file"
            name="artist[artist_works_images_attributes][1658428616694][image]"
            id="artist_artist_works_images_attributes_1658428616694_image"
            required
          />
        </div>
      )}
      {/*画像設定済み*/}
      {artworkData?.imageUrl && (
        <div className={Styles.for_sp}>
          <div className={Styles.text_center}>
            <img
              className={Styles.img_thumb + ' ' + Styles.mg_b1}
              alt="作品タイトル"
              src="https://limits.jp/assets/icon/user_default-4bc542d435fc66e3b4af93808c2d8ec65ff9d78819e6b55809bdc693ef21742a.jpg"
            />
          </div>
        </div>
      )}
      <div>
        <label htmlFor="artist_artist_works_images_attributes_0_name">
          作品名/タイトル
          <span className={Styles.txt_en}>Name</span>
        </label>
        <input
          type="text"
          value=""
          name="artist[artist_works_images_attributes][0][name]"
          id="artist_artist_works_images_attributes_0_name"
        />
      </div>
      <div>
        <label htmlFor="artist_artist_works_images_attributes_0_sequence">
          表示順
          <span className={Styles.txt_en}>Sequence</span>
        </label>
        <input
          type="number"
          step="1"
          name="artist[artist_works_images_attributes][0][sequence]"
          id="artist_artist_works_images_attributes_0_sequence"
        />
      </div>
      <div className={Styles.text_center}>
        <a className={Styles.btn_common_s + ' ' + Styles.icon_cross} href="javascript:void(0)">DELETE</a>
      </div>
    </div>
  );
};

Artwork.propTypes = {
  siteTitle: PropTypes.string,
};

Artwork.defaultProps = {
  siteTitle: ``,
};

export default Artwork;
